<template>
  <div class="addr-page grey">
    <div class="addr-form-card">
      <div class="form-item van-hairline--bottom">
        <div class="form-label">联系人</div>
        <input type="text" v-model="username" placeholder="请输入联系人" maxlength="10">
      </div>
      <div class="form-item van-hairline--bottom">
        <div class="form-label">联系电话</div>
        <input type="tel" v-model="phone" placeholder="请输入联系电话" maxlength="11">
      </div>
      <div class="form-item van-hairline--bottom">
        <div class="form-label">所在地区</div>
        <div class="form-inner addr" :class="{'placeholder': !area }" @click="showAddrPopup">
          {{ area ? province + ' ' + city + ' ' + area + ' ' + street : '请选择所在地址' }}
        </div>
        <div class="lt-addr-icon" @click="getLocation">
          <img src="~@/assets/images/icon/icon_location.svg" alt="">
        </div>
      </div>
      <div class="form-item van-hairline--bottom">
        <div class="form-label">详细地址</div>
        <textarea v-model="address" :rows="row" placeholder="请输入街道及门牌号等信息" maxlength="200" @input="autoTextarea"></textarea>
      </div>
    </div>
    <van-button type="info" 
      :loading="loading"
      class="submit-btn flex-center" 
      :class="{ 'disabled': !username || !phone || !area || !address }"
      @click="onSubmit">
      <span>保存</span>
    </van-button>

    <van-popup position="bottom" v-model="visible">
      <van-area title="选择所在地区" 
        :area-list="areaList" 
        @cancel="visible=false"
        @confirm="getAddr"
        :value="areaCode"/>
    </van-popup>
  </div>
</template>

<script>
  import { Button, Popup, Area, Toast, Dialog } from 'vant'
  import { areaList } from '@vant/area-data';
  import { getAddressDetail, updateAddress } from '@/api/common'
  import { isPhoneNumber } from '@/utils/common'

  export default { 
    components: {
      [Button.name]: Button,
      [Popup.name]: Popup,
      [Area.name]: Area,
    },
    data() {
      return {
        id: '',
        loading: false,
        province: '',
        city: '',
        area: '',
        username: '',
        phone: '',
        address: '',
        row: 2,
        areaList,
        areaCode: '',
        visible: false,
        street: ''
      }
    },
    created() {
      this.id = this.$route.params.id
      this.loadData()
    },
    methods: {
      loadData() {
        const toast = Toast.loading({
          message: '加载中...',
          forbidClick: true,
        });
        getAddressDetail({id: this.id}).then(res => {
          const { province, city, area, username, street, mobile, address, area_code } = res.data.info
          this.province = province
          this.city = city
          this.area = area
          this.address = address
          this.areaCode = area_code
          this.username = username
          this.street = street || ''
          this.phone = mobile
          toast.clear()
        })
      },
      getRow(content) {
        let row = 0
        content.split("\n").forEach(item => {
          if (item.length === 0) {
            row += 1
          } else {
            row += Math.ceil(item.replace(/[\u0391-\uFFE5]/g,"aa").length / 32)
          }
        })
        return row
      },
      autoTextarea() {
        const rows = this.getRow(this.address)
        this.row = rows > 2 ? rows : 2
      },
      showAddrPopup() {
        this.visible = true
      },
      getAddr(e) {
        this.province = e[0].name
        this.city = e[1].name
        this.area = e[2].name
        this.street = ''
        this.areaCode = e[2].code
        this.visible = false
      },
      getLocation() {
        const _this = this
        Dialog.confirm({
          title: '获取定位',
          message: '是否获取当前定位',
          confirmButtonColor: '#186AFF'
        })
        .then(() => {
          const AMap = this.$Amap || window.Amap

        const geolocation = new AMap.Geolocation({
            // 是否使用高精度定位，默认：true
            enableHighAccuracy: true,
            // 设置定位超时时间，默认：无穷大
            timeout: 10000,

          })
          geolocation.getCurrentPosition(function(status, result){
            if (status=='complete'){
              onComplete(result)
            } else {
              onError(result)
            }
          });

          function onComplete (data) {
            // data是具体的定位信息
            const geocoder = new AMap.Geocoder()

            const lnglat = [data.position.lng, data.position.lat]

            geocoder.getAddress(lnglat, function(status, result) {
              if (status === 'complete' && result.info === 'OK') {
                const info = result.regeocode.addressComponent
                console.log(info)
                // _this.address = result.regeocode.formattedAddress
                _this.address = info.neighborhood
                _this.province = info.province
                _this.city = info.city
                _this.area = info.district
                _this.street = info.township
                _this.areaCode = info.adcode
              } else {
                Toast('定位失败')
              }
            })
          }

          function onError (data) {
            // 定位出错
            console.log(data)
            Toast('定位失败')
          }
        })
        .catch(() => {
          // on cancel
        });
        
      },
      onSubmit() {
        if (!this.username || !this.phone || !this.area || !this.address || this.loading) {
          return
        }
        if (!isPhoneNumber(this.phone)) {
          Toast('手机号码格式不正确')
          return false
        }
        this.loading = true
        const params = {
          id: this.id,
          username: this.username,
          mobile: this.phone,
          province: this.province, 
          city: this.city,
          area: this.area, 
          street: this.street,
          area_code: this.areaCode,
          address: this.address
        }
        updateAddress(params).then(() => {
          Toast.success('保存成功')
          setTimeout(() => {
            this.$router.go(-1)
          }, 1000)
        }).catch(() => {
          this.loading = false
        })
      }
    }
  }
</script>

<style lang="less" scoped>
  @import './style.less';
</style>